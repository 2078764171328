import appUtils from '../../utils/appUtils'
import BaseRequest from '../BaseRequest'

export default class AuthRequest extends BaseRequest {
  me () {
    try {
      return this.get('auth/me')
    } catch (e) {
      appUtils.removeLocalUser()
      appUtils.removeLocalToken()

      return window.$router.push({ path: '/login' })
    }
  }

  login (params) {
    return this.post('auth/login', params)
  }

  getCredential (params) {
    return this.post('core/v1/auth/multiple-meeting-doctor-credential', params)
  }

  verifyMeetLink (params) {
    return this.post('core/v1/auth/verify-meeting-link', params)
  }

  sendOtpEmail (params) {
    return this.post('core/v1/auth/send-email-otp', params)
  }

  loginOtp (params) {
    return this.post('core/v1/auth/doctor-login-otp', params, null, false)
  }

  getMeetRoom (params) {
    return this.get('auth/meet-room', params)
  }

  createMeetRoom (params) {
    return this.post('auth/meet-room', params)
  }

  showMeetRoom (id, params) {
    return this.get(`auth/meet-room/${id}/show`, params)
  }

  updateMeetRoom (id, params) {
    return this.get(`auth/meet-room/${id}/update`, params)
  }

  completeMeetRoom (id) {
    return this.get(`auth/meet-room/${id}/complete`)
  }

  getConectiveDoctors (p) {
    return this.get('doctor/conective-doctors', p)
  }

  addMeetMembers (id, params) {
    return this.post(`auth/meet-room/${id}/add-members`, params)
  }

  showMeetRoomByName (roomName) {
    return this.get(`auth/meet-room/${roomName}/show-name`, null, false)
  }

  getTwilioToken (params) {
    return this.post('auth/twl-video/token', params)
  }

  getMeetRoomMembers (meetRoomId) {
    return this.get(`auth/meet-room/${meetRoomId}/members`)
  }

  getMeetRoomAttachs (meetRoomId, params) {
    return this.get(`auth/meet-room/${meetRoomId}/attachment`, params)
  }

  getTodayMeetRoomConcluding (meetRoomId) {
    return this.get(`auth/meet-room/${meetRoomId}/today-concluding`)
  }

  createMeetRoomConcluding (meetRoomId, params) {
    return this.post(`auth/meet-room/${meetRoomId}/concluding`, params)
  }

  updateMeetRoomConcluding (id, params) {
    return this.post(`auth/meet-room-concluding/${id}/update`, params)
  }

  checkHasPw () {
    return this.post('core/v1/auth/has-password')
  }

  updateInfo (p) {
    return this.post('auth/info', p)
  }

  showPublicMdtRoomByName (name, params) {
    return this.get(`auth/public-mdt-room/${name}/show-name`, params, null, false)
  }

  getMdtCases (params) {
    return this.get('auth/public-mdt-cases', params, null, false)
  }

  getPublicMdtCheckinMembers (params) {
    return this.get('auth/public-mdt-checkin', params, null, false)
  }

  publicMdtCheckin (params) {
    return this.post('auth/public-mdt-checkin', params)
  }

  publicMdtCaseStatus (id, params) {
    return this.post(`auth/public-mdt-cases/${id}/status`, params)
  }

  changeLang (params) {
    return this.post('auth/lang', params)
  }

  getLinkHodoResourceAws (params, header, showMessage = true) {
    return this.post(
      'core/v1/uploads/s3-get-rc-presigned',
      params,
      header,
      showMessage
    )
  }
}
