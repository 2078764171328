<template>
  <div id="app">
    <vue-progress-bar></vue-progress-bar>
    <component :is="layout">
      <router-view></router-view>
    </component>
    <MultiUploadProgressBox v-if="$multiUploading && $multiUploading.length" />
  </div>
</template>
<script>
import MultiUploadProgressBox from './components/MultiUploadProgressBox.vue'
import appUtils from './utils/appUtils'
import Echo from 'laravel-echo'
window.io = require('socket.io-client')
const defaultLayout = 'app'
export default {
  name: 'App',
  components: { MultiUploadProgressBox },
  async created () {
    window.$router = this.$router
    window.$progress = this.$Progress
    window.$store = this.$store

    if (!appUtils.getLocalToken()) {
      this.logOut()
    }

    await this.setUserData()
  },
  computed: {
    layout () {
      return (this.$route.meta.layout || defaultLayout) + '-layout'
    }
  },
  mounted () {
    if (appUtils.getLocalToken()) {
      this.onConnectSocket()
    }
  },
  watch: {
    $lang (lang) {
      this.$i18n.locale = lang
      appUtils.setLocalLang(lang)
    },
    $user (user) {
      if (user?.id) return

      this.setUserData()
      this.onConnectSocket()
    }
  },
  methods: {
    logOut () {
      appUtils.removeAllLocal()
    },
    async setUserData () {
      if (appUtils.getLocalUser()) {
        this.$user = appUtils.getLocalUser()
        if (this.$user.lang) {
          this.$lang = appUtils.getLangCode(this.$user.lang) || 'en'
        } else if (appUtils.getLocalLang()) {
          this.$lang = appUtils.getLocalLang()
          const langId = appUtils.getLangId(this.$lang)
          if (langId) await this.saveLang(langId)
        } else {
          this.$lang = 'vi'
        }
      } else {
        this.$lang = 'vi'
        this.$user = null
      }
    },
    async saveLang (id) {
      if (!this.$user) return
      await this.$rf
        .getRequest('AuthRequest')
        .changeLang({ lang: id })
        .then(async () => {
          // this.$toast.open({
          //   message: 'Cập nhật thành công',
          //   type: 'success'
          // })
          await this.getMe()
        })
    },
    async getMe () {
      const resp = await this.$rf.getRequest('AuthRequest').me()
      if (resp) {
        appUtils.setLocalUser(resp.data)
        const lang = appUtils.getLangCode(resp.data.lang) || 'vi'
        this.$lang = lang
        appUtils.setLocalLang(lang)
      }
    },
    async onConnectSocket () {
      // Have this in case you stop running your laravel echo server
      // console.log(typeof io)
      if (typeof io !== 'undefined') {
        if (appUtils.getLocalToken()) {
          const user = this.$user
          this.$echo = new Echo({
            broadcaster: 'socket.io',
            host: process.env.VUE_APP_ECHO_URL,
            auth: {
              headers: {
                Authorization: `Bearer ${appUtils.getLocalToken()}`
              }
            }
          })
          // if (!this.$conectedEcho) {

          // }
          // console.log(this.$echo)
          this.$conectedEcho = true
          console.log('connected echo user ' + user?.id)
        }
      }
    }
  }
}
</script>
<style>
</style>
