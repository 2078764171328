import AuthRequest from './request/AuthRequest'
import appUtils from '../utils/appUtils'
import CommonRequest from './request/CommonRequest'
import AdminRequest from './request/AdminRequest'
import ClinicRequest from './request/ClinicRequest'
import DoctorRequest from './request/DoctorRequest'

const requestMap = {
  AuthRequest,
  AdminRequest,
  CommonRequest,
  ClinicRequest,
  DoctorRequest
}
const instances = {}

export default class RequestFactory {
  getRequest (classname) {
    if (appUtils.getLocalToken()) {
      window.axios.defaults.headers.common.Authorization = 'Bearer ' + appUtils.getLocalToken()
    }

    window.axios.defaults.headers.common['Content-Type'] = 'application/json'
    const RequestClass = requestMap[classname]
    if (!RequestClass) {
      throw new Error('Invalid request class name: ' + classname)
    }

    let requestInstance = instances[classname]
    if (!requestInstance) {
      requestInstance = new RequestClass()
      instances[classname] = requestInstance
    }
    return requestInstance
  }
}
