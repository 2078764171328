import SignatureBaseRequest from '../SignatureBaseRequest'

export default class SignatureRequest extends SignatureBaseRequest {
  createSignature (params, headers) {
    return this.post('/create-signature', params, headers)
  }
  signDocument (params) {
    return this.post('/sign-document', params)
  }
  getDoctorSignatures (params) {
    return this.get('/signatures', params)
  }
  signDocumentWithJson (params) {
    return this.post('/sign-json', params)
  }
  getSignProcedures (params) {
    return this.get('/sign_procedures', params)
  }
  createEnvelope (params) {
    return this.post('/sign_envelopes', params)
  }
  signDocumentWithEnvelope (params) {
    return this.post('/signing-process', params)
  }
  getEnvelopeDetailByDocumentID (params) {
    return this.get(`/sign_envelopes/by_doc`, params)
  }
  updateEnvelope (id, params) {
    return this.put(`/sign_envelopes/${id}`, params)
  }
  getSignEnvelopeByDocumentID (params) {
    return this.get(`/sign_envelopes/by_doc`, params)
  }
  sendNotiSignDocument (params) {
    return this.post('/signing-process/send-sign', params)
  }
  getSignEnvelopeByID (id) {
    return this.get(`/sign_envelopes/${id}`)
  }
  updateSignatoryMetadata (id, params) {
    return this.put(`/signatory_metadata/${id}`, params)
  }
}
