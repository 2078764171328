import BaseRequest from '../BaseRequest'

export default class DoctorRequest extends BaseRequest {
  login (params) {
    return this.post('doctor/login', params)
  }

  getMeetRoom (params) {
    return this.get('doctor/meet-room', params)
  }

  createMeetRoom (params) {
    return this.post('doctor/meet-room', params)
  }

  showMeetRoom (id, params) {
    return this.get(`doctor/meet-room/${id}/show`, params)
  }

  updateMeetRoom (id, params) {
    return this.get(`doctor/meet-room/${id}/update`, params)
  }

  completeMeetRoom (id) {
    return this.get(`doctor/meet-room/${id}/complete`)
  }

  getConectiveDoctors (p) {
    return this.get('doctor/conective-doctors', p)
  }

  addMeetMembers (id, params) {
    return this.post(`doctor/meet-room/${id}/add-members`, params)
  }

  showMeetRoomByName (roomName) {
    return this.get(`doctor/meet-room/${roomName}/show-name`)
  }

  getTwilioToken (params) {
    return this.post('doctor/twl-video/token', params)
  }

  getMeetRoomMembers (meetRoomId) {
    return this.get(`doctor/meet-room/${meetRoomId}/members`)
  }

  getMeetRoomAttachs (meetRoomId, params) {
    return this.get(`doctor/meet-room/${meetRoomId}/attachment`, params)
  }

  getTodayMeetRoomConcluding (meetRoomId) {
    return this.get(`doctor/meet-room/${meetRoomId}/today-concluding`)
  }

  createMeetRoomConcluding (meetRoomId, params) {
    return this.post(`doctor/meet-room/${meetRoomId}/concluding`, params)
  }

  updateMeetRoomConcluding (id, params) {
    return this.post(`doctor/meet-room-concluding/${id}/update`, params)
  }

  showMdtRoomByName (name, params) {
    return this.get(
      `doctor/multidisciplinary-team/${name}/show-name`,
      params,
      null,
      false
    )
  }

  getMdtMembers (id, params) {
    return this.get(`doctor/multidisciplinary-team/${id}/member`, params)
  }

  getMdtCheckinMembers (params) {
    return this.get('doctor/mdt-checkin', params)
  }

  mdtCheckin (params) {
    return this.post('doctor/mdt-checkin', params)
  }

  getMdtDiseases (params) {
    return this.get('doctor/mdt-room-diseases', params)
  }

  showMdtDiseases (id, params) {
    return this.get(`doctor/mdt-room-diseases/${id}/show`, params)
  }

  updateMdtDiseases (id, params) {
    return this.post(`doctor/mdt-room-diseases/${id}`, params)
  }

  addMdtDiseases (params) {
    return this.post('doctor/mdt-room-diseases', params)
  }

  mdtDiseaseStatus (id, params) {
    return this.post(`doctor/mdt-room-diseases/${id}/update-status`, params)
  }

  addMdtDiseasesFile (params) {
    return this.post('doctor/mdt-room-disease-attachment', params)
  }

  createMdtDiseaseOperation (params) {
    return this.post('doctor/mdt-room-disease-operation', params)
  }

  updateMdtDiseaseOperation (id, params) {
    return this.post(`doctor/mdt-room-disease-operation/${id}`, params)
  }

  getMdtDiseaseInProcess (mdtRoomId) {
    return this.get(`doctor/mdt-room-diseases/${mdtRoomId}/in-process`)
  }

  joinMdtRoom (id, params) {
    return this.post(`doctor/multidisciplinary-team/${id}/join`, params)
  }

  mdtConcludingCreate (params) {
    return this.post('doctor/mdt-room-disease-concluding', params)
  }

  mdtConcludingUpdate (id, params) {
    return this.post(`doctor/mdt-room-disease-concluding/${id}`, params)
  }

  mdtConcludingUpload (id, params) {
    return this.post(`doctor/mdt-room-disease-concluding/${id}/upload`, params)
  }

  showPublicMdtRoomByName (name, params) {
    return this.get(
      `doctor/public-mdt-room/${name}/show-name`,
      params,
      null,
      false
    )
  }

  getMdtCases (params) {
    return this.get('doctor/public-mdt-cases', params, null, false)
  }

  getPublicMdtCheckinMembers (params) {
    return this.get('doctor/public-mdt-checkin', params, null, false)
  }

  publicMdtCheckin (params) {
    return this.post('doctor/public-mdt-checkin', params)
  }

  mdtCaseConcludingCreate (params) {
    return this.post('doctor/public-mdt-case-concluding', params)
  }

  mdtCaseConcludingUpdate (id, params) {
    return this.post(`doctor/public-mdt-case-concluding/${id}`, params)
  }

  createPublicMdtCaseOperation (params) {
    return this.post('doctor/public-mdt-case-operation', params)
  }

  updatePublicMdtCaseOperation (id, params) {
    return this.post(`doctor/public-mdt-case-operation/${id}`, params)
  }

  setEmrDocument (params) {
    if (!params) return
    return this.post(`doctor/emr-documents/printing-file`, params)
  }

  getEmrDocument (params) {
    if (!params) return
    return this.post(`doctor/emr-documents/printing-url`, params)
  }

  getDetailBADT (id, params) {
    return this.get(`v1/medical-records/${id}/master`, params)
  }

  printDocument (params) {
    return this.post('doctor/emr-documents/printing-html', params)
  }
  signMdtDiseaseDetail (params) {
    return this.post('doctor/sign', params)
  }
  updateDoctorMe (data) {
    return this.post(`doctor/me`, data, {
      'Content-Type': 'multipart/form-data'
    })
  }
  postAddPassword (data) {
    return this.post('go/core/security', data)
  }
  postUrlImage (url) {
    return this.post(`go/core/medicalRecord/file?url=${url}`)
  }
  getSignatureTimestamp (params) {
    return this.post('go/signing-process/get-timestamp', params)
  }
  getSignaturesDetail (params) {
    return this.post('go/signing-process/signatures', params)
  }
  createMdtOperation (params) {
    return this.post(`go/mdt_operation`, params)
  }
  updateMdtOperation (id, params) {
    return this.put(`go/mdt_operation/${id}`, params)
  }
  getMdtOperationByID (id) {
    return this.get(`go/mdt_operation/${id}`)
  }
  getDoctorsOfWorkspace (id, params) {
    return this.get(`doctor/clinic/${id}/doctors`, params)
  }
}
