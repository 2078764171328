import appUtils from '../utils/appUtils'

const localEndpoint = 'http://localhost:1234/api/'

export default class BaseRequest {
  getUrlPrefix () {
    return process.env.VUE_APP_BASE_API + 'api/'
  }

  post (url, data = {}, headers = null, showMessage) {
    return new Promise((resolve, reject) => {
      const options = {}
      if (headers) {
        options.headers = headers
      }

      // Show loading.
      window.$progress.start()

      window.axios
        .post(this.getUrlPrefix() + url, data, options, showMessage)
        .then((response) => {
          // Hide loading.
          window.$progress.finish()
          if (response.data.message && showMessage) {
            window.$toast.noti(true, response.data.message)
          }
          if (response.data.code === 200) {
            resolve(response.data)
            return
          }
          if (response.code === 200 || response.status === 200) {
            resolve(response)
            return
          }
          if (response.code === 406) {
            // window.location.href = "/login";

          }
        })
        .catch((error) => {
          this._errorHandler(reject, error, showMessage)
        })
    })
  }

  delete (url, data = {}, headers = null, showMessage) {
    return new Promise((resolve, reject) => {
      const options = {}
      if (headers) {
        options.headers = headers
      }

      // Show loading.
      window.$progress.start()

      window.axios
        .delete(this.getUrlPrefix() + url, data, options, showMessage)
        .then((response) => {
          // Hide loading.
          window.$progress.finish()

          if (response.data.message && showMessage) {
            window.$toast.noti(true, response.data.message)
          }
          if (response.data.code === 200) {
            resolve(response.data)
            return
          }
          if (response.code === 200 || response.status === 200) {
            resolve(response)
            return
          }
          if (response.code === 406) {
            // window.location.href = "/login";

          }
        })
        .catch((error) => {
          this._errorHandler(reject, error, showMessage)
        })
    })
  }

  put (url, data = {}, headers = null, showMessage) {
    return new Promise((resolve, reject) => {
      const options = {}
      if (headers) {
        options.headers = headers
      }

      // Show loading.
      window.$progress.start()

      window.axios
        .put(this.getUrlPrefix() + url, data, options, showMessage)
        .then((response) => {
          // Hide loading.
          window.$progress.finish()
          console.log({ response })
          if (response.data.message && showMessage) {
            window.$toast.noti(true, response.data.message)
          }
          if (response.data.code === 200) {
            resolve(response.data)
            return
          }
          if (response.code === 200 || response.status === 200) {
            resolve(response)
            return
          }
          if (response.code === 406) {
            // window.location.href = "/login";

          }
        })
        .catch((error) => {
          this._errorHandler(reject, error, showMessage)
        })
    })
  }

  get (url, params, showMessage) {
    return new Promise((resolve, reject) => {
      // Show loading.
      window.$progress.start()

      window.axios
        .get(this.getUrlPrefix() + url, { params })
        .then((response) => {
          // Hide loading.
          window.$progress.finish()
          if (response.data.code === 200) {
            resolve(response.data)
            return
          }

          if (response.data) {
            resolve(response)
          }

          // if(response.data.status === 406){
          //     window.location.href = "/login";
          //     return;
          // }
        })
        .catch((error) => {
          this._errorHandler(reject, error, showMessage)
        })
    })
  }

  _errorHandler (reject, err, showMessage = true) {
    // Hide loading.
    window.$progress.finish()
    if (err.response) {
      if (err.response?.data?.status === 414 && showMessage) {
        const firstKey = Object.keys(err?.response?.data?.message)[0]
        if (firstKey) {
          alert(err?.response?.data?.message[firstKey][0])
        }
      }
      if (err.response?.status === 422 && showMessage) {
        const messages = Object.values(err.response.data.errors).map(errs => '<span class="text-danger"> - ' + errs[0] + '</span>')
        window.$toast.noti(false, messages.join('<br />'))
      } else if ((err.response?.status === 401 || err.response?.status === 406)) {
        appUtils.removeLocalToken()
        appUtils.removeLocalUser()
        if (err?.config?.url) {
          var l = this.getLocation(err?.config?.url)
          if (l.pathname !== '/api/core/v1/auth/doctor-login-otp') {
            window.$router.push({ path: '/login' }).catch(_ => { })
          }
        }
        // window.$toast.open({
        //   message: 'Phiên đăng nhập hết hạn vui lòng đăng nhập lại',
        //   type: 'warning'
        //   // all other options
        // })
      } else if (err?.response?.data?.message && showMessage) {
        console.log(err)
        window.$toast.noti(false, err?.response?.data?.message)
      } else {
        if (showMessage) {
          window.$toast.noti(false, 'Lỗi hệ thống')
        }
      }
    }

    reject(err)
  }

  postByUrl (url, data = {}, headers = null, showMessage) {
    return new Promise((resolve, reject) => {
      const options = {}
      if (headers) {
        options.headers = headers
      }

      // Show loading.
      window.$progress.start()

      window.axios
        .post(url, data, options, showMessage)
        .then((response) => {
          // Hide loading.
          window.$progress.finish()

          if (response.data.message && showMessage) {
            window.$toast.open({
              message: response.data.message,
              type: 'success'
            })
          }
          if (response.data.code === 200) {
            resolve(response.data)
            return
          }
          if (response.code === 200 || response.status === 200) {
            resolve(response)
            return
          }
          if (response.code === 406) {
            // window.location.href = "/login";

          }
        })
        .catch((error) => {
          this._errorHandler(reject, error, showMessage)
        })
    })
  }

  getDev (url, params, showMessage, configs = null) {
    return new Promise((resolve, reject) => {
      // Show loading.
      window.$progress.start()

      window.axios

        .get(url, { params, ...configs, baseURL: localEndpoint })
        .then((response) => {
          // Hide loading.
          window.$progress.finish()

          if (response.data.code === 200) {
            resolve(response.data)
            return
          }
          if (response.status === 200 || response.status === 201) {
            resolve(response)
          }

          // if(response.data.status === 406){
          //     window.location.href = "/login";
          //     return;
          // }
        })
        .catch((error) => {
          // reject(error)
          this._errorHandler(reject, error, showMessage)
        })
    })
  }

  postDev (url, data = {}, headers = null, showMessage) {
    return new Promise((resolve, reject) => {
      let config = {}
      if (headers) {
        config.headers = headers
      }

      // Show loading.
      window.$progress.start()

      window.axios

        .post(url, data, {
          ...config,
          baseURL: localEndpoint
        })
        .then((response) => {
          // Hide loading.
          window.$progress.finish()

          if (response.data?.message && showMessage) {
            window.$toast.noti(true, response.data?.message)
          }
          if (response.data?.code === 200) {
            resolve(response.data)
            return
          }
          if (response.status === 200 || response.status === 201) {
            resolve(response)
            return
          }
          if (response.status === 406) {
            // window.location.href = "/login";
          }
        })
        .catch((error) => {
          // reject(error)
          this._errorHandler(reject, error, showMessage)
        })
    })
  }
  putDev (url, data = {}, headers = null, showMessage) {
    return new Promise((resolve, reject) => {
      let config = {}
      if (headers) {
        config.headers = headers
      }

      // Show loading.
      window.$progress.start()

      window.axios
        .put(url, data, { ...config, baseURL: localEndpoint })
        .then((response) => {
          // Hide loading.
          window.$progress.finish()

          if (response.data.message && showMessage) {
            window.$toast.noti(true, response.data.message)
          }
          if (response.data.code === 200) {
            resolve(response.data)
            return
          }
          if (response.status === 200 || response.status === 201) {
            resolve(response)
            return
          }
          if (response.status === 406) {
            // window.location.href = "/login";
          }
        })
        .catch((error) => {
          // reject(error)
          this._errorHandler(reject, error, showMessage)
        })
    })
  }

  getLocation = function (href) {
    var l = document.createElement('a')
    l.href = href
    return l
  };
}
